import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import Select from './components/Select';
import Input from './components/Input';

// Initialize GA4
ReactGA.initialize('G-PDNRQWDHMQ');

const App = () => {
  const [data, setData] = useState({});
  const [filteredStores, setFilteredStores] = useState([]);
  const [cities, setCities] = useState([]);
  const [malls, setMalls] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedMall, setSelectedMall] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetch('/city_malls_stores.json')
      .then(response => response.json())
      .then(jsonData => {
        setData(jsonData);
        setCities(Object.keys(jsonData));
        
        const allCategories = new Set();
        Object.values(jsonData).forEach(cityMalls => {
          Object.values(cityMalls).forEach(mallData => {
            mallData.stores.forEach(store => allCategories.add(store.category));
          });
        });
        setCategories(Array.from(allCategories));

        // Track page view
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      });
  }, []);

  const handleSelectChange = (name, value) => {
    switch(name) {
      case 'city':
        setSelectedCity(value);
        setSelectedMall('');
        ReactGA.event({
          category: 'User',
          action: 'Selected City',
          label: value
        });
        break;
      case 'mall':
        setSelectedMall(value);
        ReactGA.event({
          category: 'User',
          action: 'Selected Mall',
          label: value
        });
        break;
      case 'category':
        setSelectedCategory(value);
        ReactGA.event({
          category: 'User',
          action: 'Selected Category',
          label: value
        });
        break;
      default:
        console.warn(`Unexpected select name: ${name}`);
        ReactGA.event({
          category: 'Error',
          action: 'Unexpected Select Change',
          label: `${name}: ${value}`
        });
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    ReactGA.event({
      category: 'User',
      action: 'Searched',
      label: value
    });
  };

  useEffect(() => {
    if (selectedCity) {
      setMalls(Object.keys(data[selectedCity] || {}));
    } else {
      setMalls([]);
    }
  }, [selectedCity, data]);

  useEffect(() => {
    let filtered = [];
    if (selectedCity && data[selectedCity]) {
      if (selectedMall && data[selectedCity][selectedMall]) {
        filtered = data[selectedCity][selectedMall].stores || [];
      } else {
        filtered = Object.values(data[selectedCity]).flatMap(mallData => mallData.stores || []);
      }
    } else {
      filtered = Object.values(data).flatMap(cityMalls => 
        Object.values(cityMalls).flatMap(mallData => mallData.stores || [])
      );
    }

    if (selectedCategory) {
      filtered = filtered.filter(store => store.category === selectedCategory);
    }

    if (searchTerm) {
      filtered = filtered.filter(store => 
        store.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        store.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (store.location && store.location.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredStores(filtered);
  }, [selectedCity, selectedMall, selectedCategory, searchTerm, data]);

  const StoreCard = ({ store }) => {
    const cardContent = (
      <>
        <div className="h-48 bg-gray-200 flex items-center justify-center">
          {store.image ? (
            <img 
              src={store.image} 
              alt={`${store.name} logo`} 
              className="object-cover h-full w-full"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://via.placeholder.com/150?text=No+Image';
              }}
            />
          ) : (
            <span className="text-gray-500">No logo available</span>
          )}
        </div>
        <div className="p-4">
          <h2 className="text-xl font-semibold">{store.name}</h2>
          <p className="text-gray-600">{store.location}</p>
          <p className="mt-2 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
            {store.category}
          </p>
          {store.zone && <p className="text-sm text-gray-500 mt-1">{store.zone}</p>}
        </div>
      </>
    );

    const handleStoreClick = () => {
      ReactGA.event({
        category: 'User',
        action: 'Clicked Store',
        label: store.name
      });
    };

    return store.link ? (
      <a 
        href={store.link} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="bg-white shadow rounded-lg overflow-hidden block transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        onClick={handleStoreClick}
      >
        {cardContent}
      </a>
    ) : (
      <div className="bg-white shadow rounded-lg overflow-hidden">
        {cardContent}
      </div>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Shopping Mall Directory</h1>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
        <Select
          value={selectedCity}
          onChange={e => handleSelectChange('city', e.target.value)}
          placeholder="Select City"
        >
          <option value="">All Cities</option>
          {cities.map(city => (
            <option key={city} value={city}>{city}</option>
          ))}
        </Select>
        <Select
          value={selectedMall}
          onChange={e => handleSelectChange('mall', e.target.value)}
          placeholder="Select Mall"
          disabled={!selectedCity}
        >
          <option value="">All Malls</option>
          {malls.map(mall => (
            <option key={mall} value={mall}>{mall}</option>
          ))}
        </Select>
        <Select
          value={selectedCategory}
          onChange={e => handleSelectChange('category', e.target.value)}
          placeholder="Select Category"
        >
          <option value="">All Categories</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </Select>
        <Input
          type="text"
          placeholder="Search stores..."
          value={searchTerm}
          onChange={e => handleSearch(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredStores.map((store, index) => (
          <StoreCard key={index} store={store} />
        ))}
      </div>
    </div>
  );
};

export default App;